body {
    /* background-color: rgb(242, 242, 242) !important; */
    background-color: rgb(255, 255, 255) !important;
}

.slctPanel {
    width: 95% !important;
    border-radius: 10px;
    padding: 5px;
    box-shadow: rgb(214 214 214) 0px 5px 20px;
    height: 100px;
    justify-content: center;
    align-items: center;
    display: flex;
    font-weight: bold;
}

.txt-btn {
    background-color: transparent;
    border: none;
}

.primary-text-box {
    background-color: white;
    border: 1px solid lightgray;
}

.highlightButtons {
    width: 100%;
    height: 40px;
    margin-top: 20px;
    background-color: #90BB41;
    border: none;
    border-radius: 0px 50px 50px 0px;
    color: white;
}

.primry-btn {
    background-color: rgb(144, 187, 65) !important;
    border-radius: 0px !important;
    min-width: 150px !important;
    min-height: 40px;
    color: white !important;
    border: none;
}

.btn-latest {
    background-color: rgb(144, 187, 65) !important;
    min-width: 150px !important;
    min-height: 40px;
    color: white !important;
    border: none;
    border-radius: 4px !important;
}


.primry-txt-btn {
    background-color: whitesmoke !important;
    border-radius: 0px !important;
    min-width: 150px !important;
    min-height: 40px;
    border: none;
}


.secondary-btn {
    background-color: rgb(240, 82, 82) !important;
    border-radius: 0px !important;
    min-width: 150px !important;
    color: white !important;
    min-height: 40px;
    border: none;
}

.btn-1 {
    background-color: rgb(235, 235, 235) !important;
    border-radius: 0px !important;
    min-width: 150px !important;
    color: rgb(73, 73, 73) !important;
    border: none;
}


#centerModel {
    width: 250px;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.schoolVerifyPanel {
    height: 75px;
    background-color: #f0fff3;
    border: 1px solid #9aff7e;
    padding: 10px;
}

.dropZoneArea {
    border: 1px dashed #a7a8a8;
    height: 125px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.selectBtns {
    width: 250px;
    height: 50px;
    background-color: white;
    border: 2px solid rgb(212, 212, 212);
    font-weight: 500;
}

.search-list-overlay {
    padding: 15px;
    background-color: #f7f7f7;
    position: absolute;
    top: 0px;
    width: 100%;
    z-index: 2;
    border: 1px solid #e3e3e3;
    border-radius: 3px 3px 3px 3px;
    color: grey;
}

.dashboatdBtns {
    width: 100%;
    height: auto;
    margin-top: 0px;
    background-color: rgb(246, 246, 246);
    border: none;
    border-radius: 0px;
    padding-left: 20px;
    text-align: left;
    font-size: 13px;
    padding-top: 6px;
    padding-bottom: 6px;
    font-weight: normal;
}

.dashboatdBtnsActive ,.dashboatdBtns:hover{
    /* background-color: #3e65cf; */
    background-color: rgb(144, 187, 65);
    color: white;
    font-weight: 600;
    transition: 0.5s;
}

.lm-main-dashboard {
    height: 97vh;
    position: fixed;
    z-index: 1;
    top: 7vh;
    left: 0;
    overflow-x: hidden;
    padding-top: 20px;
}

.lm-main-dashboard-school {
    height: 100%;
    position: fixed;
    z-index: 1;
    top: 50px;
    left: 0;
    overflow-x: hidden;
    padding-top: 20px;
    height: 94%;
}


.prim-btn {
    background-color: #90BB41;
    min-width: 130px;
    height: 35px;
    margin-right: 15px;
    color: white;
    outline: none;
    border: none;
    padding: 5px 15px 5px 15px;
}

.prim-btn:hover {
    background-color: #4B6121;
}

.cancel-btn-qbf {
    background-color: #f14949;
    min-width: 130px;
    height: 35px;
    margin-right: 15px;
    color: white;
    outline: none;
    border: none;
    padding: 5px 15px 5px 15px;
}

.cancel-btn {
    background-color: #f14949;
    /* border-radius: 3px; */
    min-width: 100px;
    margin-right: 5px;
    color: white;
    outline: none;
    border: none;
    height: 40px;
}

.sec-btn {
    background-color: #F5F5F5;
    border-radius: 3px;
    color: #6a6a6a;
    height: 40px;
    min-width: 100px;
    border: none;
    margin-right: 5px;
    outline: none;
    padding: 5px 15px 5px 15px;
}

.dflt-Btn {
    width: 350px;
    background-color: rgb(255, 255, 255);
    padding: 5px;
    border-radius: 10px;
    cursor: pointer;
    border-color: black;
    color: black;
    /* box-shadow: 10px 10px 10px #eee; */
}

.slctd-btn {
    width: 350px;
    background-color: white;
    color: rgb(144, 187, 65);
    padding: 5px;
    border-radius: 10px;
    border: 2px solid #90BB41 !important;
    cursor: pointer;
    box-shadow: 10px 10px 10px #eee;
}

.editTxtBox {
    width: 100%;
}

.customDatePickerWidth {
    width: 100%;
}

.customDatePickerWidth input {
    width: 200px !important;
}

.btn-primary {
    background-color: white !important;
    color: black !important;
    max-height: 30px;
}

.stdnts-panel {
    height: 100%;
    background-color: whitesmoke;
}

.arrow-btn {
    color: white;
    background-color: #90BB41;
    width: 70%;
    border-radius: 5px;
}

.hghlht-Btn {
    color: rgb(144, 187, 65);
    background-color: white;

}

.unhglght-Btn {
    /* height: 35px; */
    width: auto;
    color: green;
    background-color: white;
    border-radius: 3px;
    min-width: 100px;
    margin-right: 5px;
}

.selected {
    background-color: #d2d5db;
    border: none;
    border-bottom: 1px solid gray;
}

.filterPanels {
    background-color: whitesmoke;
    border-radius: 5px;
    padding: 15px;
    margin-top: 15px;
}

.filterBtns {
    background: #fff;
    border: 0px;
    height: 30px;
    width: 120px;
    transition: 0.3s;
}

.filterBtns:hover {
    background: #e5e5e5;
    box-shadow: 0px 5px 5px rgba(44, 60, 63, 0.519);
}

.studentBtn {
    background-color: white;
    border: none;
    border-bottom: 1px solid gray;
}

.studentBtn:hover {
    background-color: rgb(236, 236, 236);
}

.tble-equal-padding {
    padding-right: 15px;
    padding-left: 15px;
}

.tbl-right-brdr {
    border-right: 1px solid #b7b7b7;
}

.tbl-btm-brdr {
    border-bottom: 1px solid #b7b7b7;
}

.failtag {
    padding: 5px 10px 5px 10px;
    background-color: red;
    color: white;
    border-radius: 25px;
    font-size: 14px;
    font-weight: 600;
}

.passtag {
    padding: 5px 10px 5px 10px;
    width: 80px;
    background-color: green;
    color: white;
    border-radius: 25px;
    font-size: 14px;
    font-weight: 600;
}

.grade-display {
    border-radius: 30%;
    border: 1px solid black;
    padding: 1px
}

.slctTmpltBtn {
    max-width: 150px;
    min-height: 70px;
    border: none;
    box-shadow: 0px 2px 5px rgb(149, 149, 149);
}

.dropZoneArea1 {
    position: absolute;
    top: 58%;
    left: 50%;
    transform: translate(-50%, 50%);
    width: 65%;
    text-align: center;
    margin: 0px;
}

.pos-absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.sendUser {
    visibility: hidden;
}

.sendUserBtn:hover>.sendUser {
    visibility: visible;
}

.sendUser {
    visibility: hidden;
}

.sendUserBtn:hover>.sendUser {
    visibility: visible;
}

.dropdown-slct-action {
    width: 100%;
    height: 50px;
    background-color: white;
    border: none;
    border: 1px solid #c4c4c4;
    border-radius: 3px;
}

.primary-panel {
    border: 1px solid lightgray;
    border-radius: 15px;
    padding: 15px;
}

.notification-popup {
    position: fixed;
    min-width: 450px;
    min-height: 100px;
    border: 1px solid lightgray;
    border-radius: 15px;
    box-shadow: 5px 5px 10px #0000002e;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    font-weight: 600;
    top: 8%;
    z-index: 999;
    background: white;
    right: 17px;
}

.school-list-panel:hover {
    background-color: lightgray;
}

.school-list-btn {
    background-color: transparent;
    border: none;
    min-height: 30px;
    width: 100%;
}

.edit-button {
    background-color: transparent;
    border: none;
    border: 1px solid lightgray;
    border-radius: 6px;
    padding: 4px 7px 4px 7px;
}

.delete-button {
    background-color: transparent;
    border: none;
    border: 1px solid lightgray;
    border-radius: 6px;
    padding: 4px 7px 4px 7px;
}

.custom-dropdown-toggle {
    position: relative;
    width: 100%;
    font-size: 15px;
    text-align: start;
    border: 1px solid rgb(218, 218, 218);
}

.custom-dropdown-toggle::after {
    display: none;
    /* Removes the arrow */
}