table {
    border: 2px solid forestgreen;
    width: 1000px;
    height: 200px;
}

th {
    border-bottom: 1px solid black;
}

td {
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    border-left: 1px solid black;
    text-align: center;
}

.save-button {
    background-color: rgb(144, 187, 65) !important;
    min-width: 100px !important;
    min-height: 40px;
    color: white !important;
    border: none;
    border-radius: 4px !important;
}
.disable_color {
    background-color:lightgrey;
    min-width: 100px !important;
    min-height: 40px;
    color: white !important;
    border: none;
    border-radius: 4px !important;
}

.cancel-button {
    background-color: #f14949;
    /* border-radius: 3px; */
    min-width: 100px !important;
    min-height: 40px;
    color: white !important;
    border: none;
    border-radius: 4px !important;
}

.form-contol {
    border: none;
    /* resize: none; */
    min-width: 200px;
    height: 100px;
    text-align: center;
    padding: 4px;
}

.key-form-control {
    border: none;
    min-width: 200px;
    height: 100%;
    text-align: center;
    padding: 4px;
    color: red;
}