@font-face {
  font-family: 'CircularStd-Book'; 
  src: url('../assets/fonts/CircularStd-Book.eot') format('woff2'),
       url('../assets/fonts/CircularStd-Book.woff') format('woff'),
       url('../assets/fonts/CircularStd-Book.ttf') format('truetype'),
       url('../assets/fonts/CircularStd-Book.eot') format('embedded-opentype'),
       url('../assets/fonts/CircularStd-Book.svg') format('svg');
       ;
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  font-family:"CircularStd-Book" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family:"CircularStd-Book" !important;
}

.txt-btn-link {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  background: none;
  border: none;
  font-size: 14px;
}

.txt-btn-link:hover, .txt-btn-link:focus {
  color: #90bb41; 
}


.dropdown-toggle::after{
  display: none !important;
}
#dropdown-basic:focus{
  border: 0px solid !important;
  outline: none !important;
  box-shadow: none !important;
  }
  .navbar-text a {
    background-color: transparent;
    color: inherit;
}

.navbar-text a:hover, .navbar-text a:focus {
    background-color: #90bb41;
    color: #ffffff !important;
}

.loadingScreen {
  background: #ffffff;
  width: 100%;
  height: 100%;
  z-index: 1000;
  position: fixed;
  z-index: 10000000;
}

.loadingScreen {
  top: 0px;
  left: 0px;
  position: absolute;
}
.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #90bb41;
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -50px;
  margin-top: -50px;
}

.loadingScreen .loader {
  width: 70px;
  height: 70px;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.MuiPopper-root .MuiMenuItem-root{
  font-size: 14px !important; 
  margin-top: 1px !important;
}

.MuiPopper-root .MuiTypography-root{
  font-size: 14px !important; 
}
.MuiPaper-root .MuiDataGrid-menuList{
  padding-top: 0px;
  padding-bottom: 0px;
}

.MuiPopover-root .MuiTablePagination-menuItem{
  font-size: 14px !important; 
}

.login-center-align {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-center-align img {
  margin-right: 10px; /* Space between image and text */
}
.custom-list-item-icon{
  min-width: 25px !important;
}
.custom-list-item-icon svg, .custom-list-item-text span{
  font-size: 14px;
}
